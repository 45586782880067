exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-online-reading-js": () => import("./../../../src/templates/online_reading.js" /* webpackChunkName: "component---src-templates-online-reading-js" */),
  "component---src-templates-table-of-contents-js": () => import("./../../../src/templates/table_of_contents.js" /* webpackChunkName: "component---src-templates-table-of-contents-js" */)
}

